@media only screen and (max-width:1399px){
    .login-form {
        height: 600px;
    }
}


@media only screen and (max-width:1024px){
    .login-form {
        height: 52vw;
    }
}

@media only screen and (max-width:991px){
    .login-form {
        height: 70vw;
    }
}

@media only screen and (max-width:767px){
.row_col_full{
    min-height: auto;
    align-items: center;
}

section.login_page_inner {
    align-items: start;
}
.oborna_login_sec .login-form {
    width: 100%;
    height: 100%;
}
}

@media only screen and (min-width:768px) and (max-width:1024px){
    .login-right-image {
        width: 30vw;
        height: 26vw;
    }
    .login-form {
        background-color: #ffffff;
        /* height: 512px; */
        width: 100%;
        margin: auto;
        padding: 31px;
    }
    .font_40 {
        font-size: 30px;
        line-height: 40px;
    }
    .font_20 {
        font-size: 16px;
        line-height: 20px;
    }
    .form_input {
        height: 40px;
    }
    .font_black_16 {
        font-size: 14px;
        line-height: 20px;
    }
    .font_18 {
        font-size: 16px;
        line-height: 20px;
    }
}