@font-face {
    font-family: "Product-sans";
    src: url("../fonts/Product-Sans-Regular.woff2");
    src: url("../fonts/Product-Sans-Regular.woff");
}


:root {
    --primary: #2C2C2C;
}

body {
    /* font-family: "Manrope", sans-serif; */
    margin: 0;
    padding: 0;
    color: var(--primary);
    background-color: #fcfcfc;
    /* font-family: "Product-sans"; */
    font-family: "Montserrat", sans-serif;

}

.text_black{
    color: var(--primary);
}

.bg-primary-theme {
    background-color: #D9F0FF;
}

.bg-gray-theme {
    background-color: #F4F4F4;
}

.bg-white-theme {
    background-color: #fcfcfc;
}

.bg-black-theme {
    background-color: var(--primary);
}

/* h1,h2{
    font-family: "Gowun Batang", serif;
} */

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover,
a:focus {
    /* color: inherit; */
    text-decoration: none;
}

.btn,
button {
    font-family: "Montserrat", sans-serif;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

button:focus {
    outline: 0;
    box-shadow: none;
}

.font_10 {
    font-size: 10px;
    line-height: 13px;
}

.font_12 {
    font-size: 12px;
    line-height: 15px;
}

.font_black_14 {
    font-size: 14px;
    line-height: 17px;
    color: #2c2c2c;
}

.font_black_12 {
    font-size: 12px;
    line-height: 17px;
    color: #2c2c2c;
}

.font_white_14 {
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.font_white_16 {
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}


.font_black_16 {
    font-size: 16px;
    line-height: 24px;
    color: #2C2C2C;
}

.font_18 {
    font-size: 18px;
    line-height: 22px;
}


.font_20 {
    font-size: 24px;
    line-height: 25px;
}

.font_22 {
    font-size: 22px;
    line-height: 27px;
    color: #2C2C2C;
}

.font_24 {
    font-size: 24px;
    line-height: 30px;
    color: #2C2C2C;
}


.font_40 {
    font-size: 40px;
    line-height: 50px;
}

.font_400 {
    font-weight: 400;
}

.font_500 {
    font-weight: 500;
}

.font_600 {
    font-weight: 600;
}

.font_700 {
    font-weight: 700;
}

.lh-21{
    line-height: 21px;
}

.multiselect-btn {
    color: #2C2C2C;
    border-color: #2C2C2C !important;
    background-color: #ffffff;
    padding: 10px 15px;
}

.multiselect-btn.navy_btn,
.multiselect-btn.navy_btn:hover {
    background-color: #0D79C2;
    color: #ffffff;
    border: 1px solid #0D79C2 !important;
    padding: 10px 15px;
}


.primary-btn {
    background-color: #1A1A1A;
    color: #ffffff;
    height: 48px;
    border-radius: 8px;
}

.primary-btn:hover {
    background-color: #2C2C2C;
    color: #ffffff;
}

.secondary-btn {
    color: #2C2C2C;
    border-color: #2C2C2C;
    background-color: #ffffff;
    padding: 10px 15px;
}



.secondary-btn:hover {
    color: #2C2C2C;
    border-color: #2C2C2C;
    background-color: #efefef;
    padding: 10px 15px;
}

.sky_btn {
    background-color: #129CF1;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
}

.smoke_btn {
    background-color: #ECEFF2;
    color: #2C2C2C;
    border: none;
}

.navy_btn {
    background-color: #0D79C2;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
}

.navy_btn:hover {
    background-color: #0c5f97;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
}


.form_input {
    height: 48px;
    border-radius: 8px;
    /* border: 1px solid #5D5D5D; */
    border: 1px solid #d3d5d7;
}

.h-48 {
    height: 48px;
}

.h-38 {
    height: 38px;
}

.main-logo {
    width: 180px;
}

header {
    padding: 0;
}


.lang-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* column-gap: 12px; */
}

.lang-wrapper .lang-select {
    border: none;
    background: transparent;
    cursor: pointer;
}

.lang-wrapper .lang-select:is(:focus-visible, :focus) {
    border: none;
    outline: none;
    box-shadow: none;
}

.request-btn {
    padding: 12px 20px;
    border-radius: 4px;
    display: inline-block;
}


section.login_page_inner {
    min-height: calc(100vh - 145px);
    display: flex;
    justify-content: center;
    align-items: center;
}


.login-form {
    background-color: #ffffff;
    /* height: 512px; */
    width: 480px;
    margin: auto;
    padding: 50px;
    border-radius: 30px;
    height: 30vw;
}

.hide-text {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0px, -50%);
    color: #2C2C2C;
}

.password-input {
    padding-right: 55px;
}

.login-right-image {
    width: 560px;
    height: 480px;
}

.login-right-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px;
}

footer {
    padding: 15px 0;
}

/* styles.css */
/* .nav-link {
    color: #007bff; Default link color
    transition: background-color 0.3s;
  }
  
  .nav-link:hover {
    background-color: rgba(0, 123, 255, 0.1); 
  }
  
  .nav-link.active {
    background-color: #007bff; 
    color: white; 
  } */


/* messaging pages*/

.sidebar-wrapper {
    width: 300px;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    bottom: 0;
    transition: 0.5s all;
}

.content-wrapper {
    width: calc(100% - 300px);
    /* padding-left: 325px !important; */
    background-color: #ffffff;
    padding: 0;
    margin-left: auto;
    transition: 0.5s all;
}

.sidebar-wrapper li {
    list-style-type: none;
}

.sidebar-wrapper .sidebar-menu .nav-link {
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
}

.sidebar-wrapper .sidebar-menu .nav-link.active {
    /* background-color: #D9F0FF; */
    font-weight: 700;
}

.sidebar-wrapper .sidebar-menu .nav-link:hover {
    color: #2C2C2C;
    font-weight: 700;
}

.sidebar-wrapper .sidebar-menu .nav-link svg path {
    fill: #2C2C2C;
}

.sidebar-wrapper .sidebar-menu .nav-link.active svg path {
    fill: #0D79C2;
}


.sidebar-wrapper .sidebar-menu .nav-item {
    margin: 5px 17px;
}

.sidebar-wrapper .sidebar-menu .side-nav-icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

.sidebar-wrapper .sidebar-menu i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}


.layout-wrapper.close .header {
    width: calc(100% - 80px);
    left: 80px;
    transition: 0.5s all;
}

.layout-wrapper.close .profile_tabs .nav-tabs {
    margin: 0px 15px 24px;
}

.layout-wrapper.close .sidebar-wrapper {
    width: 80px;
    transition: 0.5s all;
}


.layout-wrapper.close .content-wrapper {
    width: calc(100% - 80px);
    transition: 0.5s all;
}

.layout-wrapper.close .sidebar-wrapper .sidebar-menu .nav-link {
  font-size: 0px;
}


.layout-wrapper.close .sidebar-wrapper .sidebar-menu .side-nav-icon {
    margin-right: 0px;
}

.layout-wrapper.close .content-inner {
    padding: 30px 20px;
}
.layout-wrapper.close .side_bottom_arw span {
    transform: rotate(180deg);
}



.sidebar-plan {
    margin: 0 17px;

}

.sidebar-plan .card {
    border-radius: 20px;
    border: none;
}

.sidebar-plan .card .card-body {
    padding: 20px;
}

.sidebar-plan .progress-bar {
    border-radius: 30px;
}

.sidebar-plan .plan-btn {
    letter-spacing: 1px;
}

.plan-help {
    margin: 14px 0px;
}

.plan-help span {
    color: #707070;
}

.menu-plan-wrapper {
    height: calc(100vh - 50px);
    padding-top: 20px;
    position: relative;
}



.content-wrapper li {
    list-style-type: none;
}

.profile-account {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.input.search_message {
    height: 38px;
    background-color: #F4F4F4;
    border-radius: 10px;
    padding: 6px 10px;
}

.content-inner {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 30px 0px 0px 0px;
    margin-top: 70px;
    min-height: calc(100vh - 130px);
}

.header {
    padding: 15px 30px;
    position: fixed;
    width: calc(100% - 300px);
    left: 300px;
    top: 0;
    background-color: #fff;
    z-index: 9;
    transition: 0.5s all;
}

.content-inner .search-wrapper {
    position: relative;
    padding: 0;
    margin: 0 16px 0px 24px;
}

.content-inner .search-wrapper input {
    background-color: #ffffff08;
    padding-right: 40px;
    border-radius: 6px;
    border: 1px solid #ccc7c7;
}

.content-inner .search-wrapper i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}


.message-filter-wrapper .form-select {
    background: transparent;
    border: none;
    font-weight: 700;
    padding-right: 40px;
    margin-left: 10px;
}

.message-filter-wrapper {
    column-gap: 0;
    height: 38px;
    margin: 0;
    border-radius: 6px;
    position: relative;
    padding-right: 0px;
    border: 1px solid #ccc7c7;
}

.message-filter-wrapper i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.message-filter-wrapper .w-nowrap {
    white-space: nowrap;
}

.green-clr {
    color: #16C098;
}

.inactive_btn {
    background-color: #FFC5C5;
    border: 1px solid #DF0404;
    color: #DF0404;
}

.pending_btn {
    background-color: #dfb50e;
    border: 1px solid #dfb50e;
    color: #ffffff;
}

.failed_btn {
    background-color: #FFC5C5;
    border: 1px solid #DF0404;
    color: #DF0404;
}

.active_btn {
    background-color: #299D86;
    border: 1px solid #299D86;
    color: #ffffff;
    font-weight: 700;
}

.sent_btn {
    background-color: #2c2c2c;
    border: 1px solid #2c2c2c;
    color: #ffffff;
    font-weight: 700;
}

.content-inner .table .thead-light th {
    font-size: 14px;
    color: #2c2c2c !important;
    font-weight: 700;
    padding: 15px !important;
}

.content-inner .table td {
    font-size: 14px !important;
    line-height: 16px;
    vertical-align: middle;
    padding: 15px !important;
    background: transparent;
}

.content-inner .table tbody tr:nth-child(odd) {
    background-color: #F5F6F8;
}

.content-inner .table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

.content-inner .table tbody tr:hover {
    background-color: #F5F6F8;
}

/* .content-inner .table tbody tr:hover td:first-child{
    border-radius: 10px 0px 0px 10px;
}
.content-inner .table tbody tr:hover td:last-child{
    border-radius: 0px 10px 10px 0px;
} */

.content-inner .card {
    /* box-shadow: 0px 10px 60px 0px #E2ECF980;
    border-radius: 30px; */
    border: none;
    padding: 10px 5px;
}

.pagination {
    display: flex;
    column-gap: 15px;
}

.content-inner .pagination .btn-secondary {
    background-color: #edeef1 !important;
    border-color: #d5d5d5 !important;
    color: #142637 !important;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.content-inner .pagination .btn-secondary.active {
    background-color: #ffffff !important;
    color: #2c2c2c !important;
    border-color: #d5d5d5 !important;
    width: 40px;
}

.content-inner table {
    border-top: 2px solid #ECEFF2;
    border-bottom: 2px solid #ECEFF2;
}

.form-select:focus-visible,
.form-control:focus-visible {
    box-shadow: none;
    background: transparent;
}

.country-code {
    border: 0;
    background: transparent;
    border-right: 2px solid #000000;
    border-radius: 0px;
}

.message-filter-wrapper .form-control {
    background: transparent;
    padding: 5px 40px 5px 10px;
    margin-left: 10px;
    border: none;
}

.drop-arrow {
    background-color: transparent;
    color: #000;
    border: none;
}

.customizer_card {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    margin-bottom: 30px;
}

.customizer_wrapper .customizer_link {
    width: 215px;
    display: inline-block;
    padding: 15px 20px;
    color: #ffffff;
    border-radius: 6px;
    height: 100%;
}

.customizer_wrapper .nav-tabs {
    border: none;
}

.customizer_wrapper .nav-tabs .nav-item.show .nav-link,
.customizer_wrapper .nav-tabs .nav-link.active {
    border-color: transparent;
}

.customizer_wrapper .nav-tabs .nav-link.active .bg-black-theme {
    background-color: #0D79C2;
}

.customizer_wrapper .nav-tabs .nav-link {
    padding: 10px;
    cursor: pointer;
    height: 100%;
}

.customizer_wrapper .customizer_card_icon {
    width: 24px;
    height: 24px;
    margin-bottom: 12px;
}

.customizer_wrapper .tab-content .tab-pane {
    padding: 25px 15px 10px;
}



.logo-wrapper {
    height: 70px;
}

.profile-account .profile_icon {
    width: 32px;
    height: 32px;
    background-color: #F5F6F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.profile-account .user_title {
    line-height: 18px;
}

.action_icon_wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.action_icon_wrapper .action_icon {
    width: 24px;
    height: 24px;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcfcfc;
    cursor: pointer;
}


.msg_area .primary-btn {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 12px;
    height: auto;
}


.template_multibtn {
    display: flex;
    align-items: start;
    column-gap: 6px;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
}

.template_multibtn .secondary-btn:hover {
    background-color: #ffffff;
}


.table_user_title {
    background-color: #ECEFF2;
    padding: 6px 10px;
    display: inline-flex;
    border-radius: 4px;
    column-gap: 5px;
}

.template_data_wrapper {
    height: 550px;
    overflow-y: scroll;
}

.template_data_wrapper .card_active .template_data {
    border: 2px solid #2c2c2c;
}

/* width */
.template_data_wrapper::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.template_data_wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.template_data_wrapper::-webkit-scrollbar-thumb {
    background: #2c2c2c;
    border-radius: 10px;
}



.template_data {
    display: flex;
    border: 1px solid #F5F6F8;
    padding: 10px;
    border-radius: 6px;
}


.down_arw {
    position: relative;
    background-color: transparent;
}

.down_arw:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%, 0%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000000;
    clear: both;
    z-index: 0;
}


.textarea_count {
    position: absolute;
    right: 10px;
    bottom: 6px;
    font-size: 12px;
    color: #B9B9B9;
}

.side_bottom_arw span {
    width: 35px;
    height: 35px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 51px;
    right: 30px;
    border: 1px solid #2c2c2c;
    border-radius: 50%;
    cursor: pointer;
}


.sms_content_wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 20px;
}

.sms_content_wrapper .delivery_info_wrapper {
    width: 30%;
    padding: 16px;
    border-radius: 6px;
    height: fit-content;
}

.sms_content_wrapper .del_textarea_wrapper {
    width: 70%;
    padding: 11px 16px;
    border-radius: 6px;
}

.sms_content_wrapper.add_temp .del_textarea_wrapper {
    width: 28%;
}

.sms_content_wrapper.template_wrapper {
    width: 0%;
}

.sms_content_wrapper.add_temp .template_wrapper {
    width: 40%;
}

select option {
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left center;
    padding-left: 25px;
}

select option[value="us"] {
    background-image: url('../images/logo.png');
}

select option[value="gb"] {
    background-image: url('../images/logo.png');
}


.phn_code .react-select__control {
    border: none;
    height: 100%;
    border-radius: 8px !important;
}

.phn_code .form_input {
    border-radius: 8px !important;
    width: 100px;
}

.phn_code .react-select__indicators {
    display: none;
}

.phn_code.down_arw:after {
    content: '';
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translate(-50%, 0%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000000;
    clear: both;
    z-index: 0;
}

.template_buttons {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-top: 30px;
}

.template_buttons button {
    width: 185px;
    min-height: 50px;
    font-size: 14px;
}

.add_temp .del_textarea_wrapper .template_buttons {
    display: none;
}

.oborna_login_sec {
    min-height: calc(100vh) !important;
    width: 100%;
    background: #ffffff;
}

.login-form .incorrect_icon {
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin-bottom: 24px;
}


.oborna_login_sec .login-form {
    width: 400px;
    margin: auto;
    border-radius: 0;

}

.oborna_login_sec .login-form .form-group {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.oborna_login_sec .login-form .form-group label {
    width: 70px;
}

.oborna_login_sec .login-form .form-group .input_box {
    width: calc(100% - 70px);
}

.oborna_login_sec .login-form .form-group .form_input {
    height: 35px;
    border-radius: 8px;
    border: 1px solid #ECEFF2;
    font-size: 12px;
    border-radius: 5px;
}

.oborna_login_sec .primary-btn {
    background-color: #0D79C2;
    height: 45px;
    border-radius: 5px;
    border-color: #0D79C2;
}

.oborna_login_sec .login-right-image.grey_box {
    width: 100%;
    height: 100vh;
    background: #FCFCFC;
    border-radius: 18px;
    padding: 15px 5px;
}

.row_col_full {
    min-height: calc(100vh - 60px);
    display: flex;
    align-items: center;
}

.oborna_login_sec .errorVal {
    position: absolute;
    top: 34px;
    font-size: 10px;
    font-weight: 500;
    padding-left: 14px;
}

.psw-recovery .login-heading {
    margin-bottom: 32px;
}

.psw-recovery .sent_btn {
    height: 42px;
}

.psw-recovery .sent_btn {
    background-color: #2c2c2c !important;
    border: 1px solid #2c2c2c !important;
    color: #ffffff !important;
}


/* paper letter tab */
.paper_country.form_input {
    cursor: pointer;
}

.paper_country .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
}

/* .paper_country .css-b62m3t-container:focus-visible, .paper_country .react-select__value-container:focus-visible {
    outline: none;
    border: none;   
} */

.paper_country .react-select__control {
    border: none;
    box-shadow: none !important;
    min-height: auto;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.paper_country .react-select__value-container {
    padding-left: 0px;
}


.paper_country .react-select__indicator-separator {
    background-color: transparent;
}

.paper_country .react-select__indicator.react-select__dropdown-indicator {
    display: none;
}

/* upload file messaeg */

.files_uploading,
.upload_file_wrapper .image-preview {
    width: 108px;
    height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    position: relative;
}

.files_uploading .upload_plus {
    width: 24px;
    height: 24px;
}

.files_uploading input#imageUpload {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.upload_file_wrapper .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.upload_file_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 12px;
    row-gap: 12px;
}

.upload_file_wrapper .image-previews {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 12px;
    row-gap: 12px;
}

.upload_file_wrapper .image-preview .delete-button {
    position: absolute;
    right: 2px;
    bottom: 2px;
    background-color: rgb(253 253 253 / 30%);
    border: none;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload_file_wrapper .image-preview .delete-button img {
    width: 12px;
    height: 14px;
    filter: invert(1);
}



.schedule_datepicker .react-datepicker-wrapper {
    width: 100%;

}

.schedule_datepicker {
    position: relative;
}

.schedule_datepicker .form_input {
    cursor: pointer;
}

.schedule_datepicker .datepicker_icon {
    position: absolute;
    top: 16px;
    right: 25px;
    width: 14px;
    height: 14px;
}


.name_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;
}

.delete_name_wrapper {
    width: 56px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2C2C2C;
    border-radius: 4px;
    cursor: pointer;
}

.delete_name_wrapper .delete_icon {
    filter: invert(1);
    width: 17px;
    height: 17px;
}


/* Profile page & edit profile */

.edit_profile_wrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 32px;
    width: 100%;
    overflow: auto;
}


.profile_tabs {
    width: 100%;
    overflow: auto;
}

.tab-content{
    transition: 0.3s all;
}

.page_load .tab-content{
    filter: blur(20px);
}

.page_load .spinner-border {
    display: flex;
}
.spinner-border {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
}

.edit_profile_box {
    width: 423px;
}

.edit_profile_box label{
    margin: 15px 0;
}

.edit_profile_box label.backup-phone {
    margin: 6px 0;
}


.edit_profile_box .template_buttons button {
    width: 126px;
    min-height: 32px;
    font-size: 12px;
    padding: 5px 10px;
    height: 32px;
    border-radius: 4px;
}

.profile_tabs .nav-tabs {
    display: flex;
    column-gap: 32px;
    align-items: center;
    margin-bottom: 24px;
    transition: 0.5s all;
}

.profile_tabs .nav-tabs .currentActive .card_heading {
    font-weight: 700;
}

.profile_tabs .content-wrapper a:has(.active) li {
    list-style-type: none;
    border-bottom: 1px solid red;
}

.currentActive{
    border-bottom: 2px solid #0D79C2;
}

.verification-code {
    max-width: 300px;
    position: relative;
    margin: 0px auto;
    text-align: center;
    display: flex;
    column-gap: 10px;
}

.verification-code .form-control {
    width: 60px;
    height: 60px;
    padding: 10px;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #ECEFF2;
    color: var(--Color, #2C2C2C);
    font-size: 24px;
    font-weight: 700;
    border-radius: 6px;
}

.verification-code .form-control:focus-visible:focus-visible {
   
    outline: none !important;

}
.otp_btn .btn-secondary {
    height: 42px;
}

.otp_btn .bg-transprint {
    background: transparent;
    border: none;
    outline: none;
    color: #B9B9B9;
    font-size: 12px;
    margin-left: 5px;
}

.verification-code .form-control.active {
    border: none !important;
    outline: none !important;
    border-radius: 6px;
    background: #0D79C2;
    color: #ffffff !important;
}
.otp_btn .bg-transprint.btn-active {
    background: #0D79C2;
    color: #fff;
    cursor: pointer;
  }
  
  .btn-inactive {
    cursor: not-allowed;
  }

.oborna_login_sec .psw-recovery .errorVal {
    /* top: 42px; */
    text-align: left;
}
/* Add this to your CSS file */
.disabled-btn {
    pointer-events: none;  /* Prevent clicking */
    opacity: 0.5;          /* Dim the button */
  }

  .group_count {
    background-color: #2C2C2C;
    color: #fdfdfd;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 14px;
    border-radius: 4px;
    cursor: pointer;
}
.react-select__control {
    height: 48px;
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-weight: 600;
}
.react-select__option {
    font-size: 12px !important;
    font-weight: 600;
}


.react-select__dropdown-indicator, .react-select__indicator-separator {
    display: none !important;
}

.react-select__menu-list::-webkit-scrollbar {
    width:6px;
  }
  
  .react-select__menu-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  .react-select__menu-list::-webkit-scrollbar-thumb {
    background:#6e6d6d; 
    border-radius: 10px;
  }
  .personalization{
    margin-left: 44px;
  }

  .padding_pg {
    padding: 25px 15px 10px;
}


.ai_avatar .back_list_btn{
    background-color: #2C2C2C;
}

.ai_avatar .side_arrow {
    width: 8px;
    height: 8px;
    margin: 0 12px;
}

.personalize_btn {
    background-color: #ECEFF2;
    height: 25px;
    color: #2c2c2c;
    font-size: 12px;
    line-height: 12px;
    padding: 5px 10px;
    border-radius: 6px;
}

.ai_avatar .nav-tabs .nav-link{
    border: none;
}
.ai_avatar .nav-tabs .nav-link:focus, .ai_avatar .nav-tabs .nav-link:hover {
    isolation: auto;
    border: none;
    color: var(--primary);
}
.ai_avatar .nav-link:focus-visible{
    box-shadow: none;
}



textarea::placeholder{
    padding: 0.5rem 3px;
}


.newest_filter .react-select__menu{
    width: 150px;
    right: -20px;
}